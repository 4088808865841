/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };
  $('.video-tab').each(function(){
    videoTab = $(this);
    videoTabCount = $(this).attr('data-tab');
    console.log(videoTabCount);
    videoData = $('div[data-tab=' + videoTabCount+'] .video-gal-1 .video-tab-data').html();
    videoTitle = $('div[data-tab=' + videoTabCount+'] .video-gal-1 h3').html();
    videoContent = $('div[data-tab=' + videoTabCount+'] .video-gal-1 p').html();
    $('div[data-tab=' + videoTabCount + '] .main-video').html(videoData);
    $('div[data-tab=' + videoTabCount + '] .main-video-title').html(videoTitle);
    $('div[data-tab=' + videoTabCount + '] .main-video-description').html(videoContent);
  });
  $('.tab-button').on('click', function(){
    var tabShow = $(this).attr('data-tabtarget');
    $('.tab-button').removeClass('video-tab-active');
    $(this).addClass('video-tab-active');
    $('div[data-tab='+ tabShow +']').addClass('video-tab-show').siblings().removeClass('video-tab-show');
  });
  $('.tab-video').on('click', function(){
    theVideo = $(this).children('.video-tab-data').html();
    theVideoDescription = $(this).children('p').html();
    theVideoTitle = $(this).children('h3').html();
    console.log(theVideoDescription);
    pearent = $(this).children('.video-tab-data').attr('data-pearent');
    $('div[data-tab=' + pearent + '] .main-video').html(theVideo);
    $('div[data-tab=' + pearent + '] .main-video-description').html(theVideoDescription);
    $('div[data-tab=' + pearent + '] .main-video-title').html(theVideoTitle);
  });
  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
